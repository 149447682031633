import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateRewardFormPayload,
  UpdateRewardFormPayload,
  RewardResponse,
  RewardsResponse,
  PaginatedRewards,
} from '@/models/reward';

const defaultPaginatedState = {
  data: null,
  page: 1,
  limit: 10,
  totalRecords: 0,
  totalPages: 0,
};

@Module({
  namespaced: true,
  dynamic: true,
  name: `reward`,
  store,
})
class RewardStore extends VuexModule {
  isPendingRewards = false;
  isPendingReward = false;
  isCreatingReward = false;
  isUpdatingReward = false;
  isDeletingReward = false;

  rewardsError: any = null;
  rewardError: any = null;
  createRewardError: any = null;
  updateRewardError: any = null;
  deleteRewardError: any = null;
  rewards: PaginatedRewards = {
    ...defaultPaginatedState,
  };
  reward: RewardResponse['data'] | null = null;

  @Mutation
  GET_REWARDS_LOADING() {
    this.isPendingRewards = true;
    this.rewardsError = null;
    this.rewards = defaultPaginatedState;
  }
  @Mutation
  GET_REWARDS_SUCCESS(payload: PaginatedRewards) {
    this.isPendingRewards = false;
    this.rewards = {
      ...this.rewards,
      ...payload,
    };
  }
  @Mutation
  GET_REWARDS_ERROR(payload: any) {
    this.isPendingRewards = false;
    this.rewardsError = payload;
  }

  @Mutation
  GET_REWARD_LOADING() {
    this.isPendingReward = true;
    this.rewardError = null;
    this.reward = null;
  }
  @Mutation
  GET_REWARD_SUCCESS(payload: RewardResponse['data']) {
    this.isPendingReward = false;
    this.reward = payload;
  }
  @Mutation
  GET_REWARD_ERROR(payload: any) {
    this.isPendingReward = false;
    this.rewardError = payload;
  }

  @Mutation
  CREATE_REWARD_LOADING() {
    this.isCreatingReward = true;
    this.createRewardError = null;
  }
  @Mutation
  CREATE_REWARD_SUCCESS(payload: any) {
    this.isCreatingReward = false;
  }
  @Mutation
  CREATE_REWARDS_ERROR(payload: any) {
    this.isCreatingReward = false;
    this.createRewardError = payload;
  }

  @Mutation
  UPDATE_REWARD_LOADING() {
    this.isUpdatingReward = true;
    this.updateRewardError = null;
  }
  @Mutation
  UPDATE_REWARD_SUCCESS() {
    this.isUpdatingReward = false;
  }
  @Mutation
  UPDATE_REWARDS_ERROR(payload: any) {
    this.isUpdatingReward = false;
    this.updateRewardError = payload;
  }

  @Mutation
  DELETE_REWARD_LOADING() {
    this.isDeletingReward = true;
    this.deleteRewardError = null;
  }
  @Mutation
  DELETE_REWARD_SUCCESS() {
    this.isDeletingReward = false;
  }
  @Mutation
  DELETE_REWARDS_ERROR(payload: any) {
    this.isDeletingReward = false;
    this.deleteRewardError = payload;
  }

  @Action
  getRewards({ keyword = '', page = 1, limit = 10 }) {
    this.context.commit(`GET_REWARDS_LOADING`);

    axios
      .get<RewardsResponse>(`/reward/list`, {
        params: {
          keyword,
          page,
          limit,
        },
      })
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_REWARDS_SUCCESS`, {
            data: response.data.data.objects,
            page: response.headers[`x-page`],
            limit: response.headers[`x-limit`],
            totalRecords: response.headers[`x-total-records`],
            totalPages: response.headers[`x-total-pages`],
          });
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching rewards data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`GET_REWARDS_ERROR`, error);
      });
  }

  @Action
  getReward(id: number) {
    this.context.commit(`GET_REWARD_LOADING`);

    axios
      .get<RewardResponse>(`/reward/list/${id}`)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_REWARD_SUCCESS`, response.data.data);
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching reward data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`GET_REWARD_ERROR`, error);
      });
  }

  @Action
  createReward(params: CreateRewardFormPayload) {
    this.context.commit(`CREATE_REWARD_LOADING`);

    axios
      .post<RewardResponse>(`/reward`, params)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`CREATE_REWARD_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created reward.`,
          });
        }

        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating reward data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`CREATE_REWARD_ERROR`, error);
      });
  }

  @Action
  updateReward(payload: UpdateRewardFormPayload) {
    this.context.commit(`UPDATE_REWARD_LOADING`);

    axios
      .put<RewardResponse>(`/reward/${payload.id}`, { ...payload }, { timeout: 60000 })
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`UPDATE_REWARD_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Reward successfully update!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with reward update process. Please try again. Error: ${response.data.message}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`UPDATE_REWARD_ERROR`, error);
      });
  }

  //   @Action
  //   deleteReward(id: number) {
  //     this.context.commit(`DELETE_REWARD_LOADING`);

  //     axios
  //       .delete(`/reward/${id}`)
  //       .then((response) => {
  //         if (response.data.code === 200) {
  //           this.context.commit(`DELETE_REWARD_SUCCESS`, response.data.data);

  //           Toast.open({
  //             type: `is-info`,
  //             duration: 3000,
  //             message: `Reward successfully deleted!`,
  //           });
  //         }

  //         if (response.data.code === 500) {
  //           Toast.open({
  //             type: `is-danger`,
  //             duration: 3000, // 3 Seconds
  //             message: `There is an issue with reward delete process. Please try again`,
  //           });
  //           throw new Error(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         this.context.commit(`DELETE_REWARDS_ERROR`, error);
  //       });
  //   }
}

export const RewardStores = getModule(RewardStore);
